import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

const SubspaceIcon2: SvgIconComponent = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fill={props.fill ?? 'white'}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8781 13.8439C8.96628 15.8455 4.95365 15.5921 2.31445 13.0416C-0.6984 10.13 -0.780483 5.32731 2.13112 2.31445C5.04271 -0.6984 9.84543 -0.780483 12.8583 2.13112C15.4975 4.68163 15.8878 8.6833 13.9869 11.6618L17.8281 15.374C19.506 14.5369 21.5986 14.7978 23.0238 16.175C24.8315 17.922 24.8807 20.8036 23.1338 22.6113C21.3868 24.419 18.5052 24.4683 16.6975 22.7213C15.2723 21.3441 14.9401 19.2616 15.7193 17.5561L11.8781 13.8439ZM4.39439 10.6354C6.21612 12.3959 9.12009 12.3463 10.8806 10.5246C12.6411 8.70285 12.5915 5.79888 10.7697 4.03838C8.94801 2.27787 6.04404 2.32751 4.28354 4.14923C2.52304 5.97096 2.57267 8.87493 4.39439 10.6354ZM18.8062 20.5392C19.4088 21.1215 20.3693 21.1051 20.9517 20.5025C21.534 19.9 21.5176 18.9394 20.915 18.3571C20.3124 17.7748 19.3519 17.7912 18.7696 18.3938C18.1872 18.9964 18.2037 19.9569 18.8062 20.5392Z"
      />
    </SvgIcon>
  );
};

SubspaceIcon2.muiName = 'subspace';

export default SubspaceIcon2;
